import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				Coffee Haven'a hoş geldiniz
			</title>
			<meta name={"description"} content={"Mükemmel Günlük Kaçamınız"} />
			<meta property={"og:title"} content={"Coffee Haven'a hoş geldiniz"} />
			<meta property={"og:description"} content={"Mükemmel Günlük Kaçamınız"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/668bd1731d712a002335f7d7/images/1-1%20%281%29.jpg?v=2024-07-08T11:54:17.127Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/668bd1731d712a002335f7d7/images/1-1%20%281%29.jpg?v=2024-07-08T11:54:17.127Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/668bd1731d712a002335f7d7/images/1-1%20%281%29.jpg?v=2024-07-08T11:54:17.127Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/668bd1731d712a002335f7d7/images/1-1%20%281%29.jpg?v=2024-07-08T11:54:17.127Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/668bd1731d712a002335f7d7/images/1-1%20%281%29.jpg?v=2024-07-08T11:54:17.127Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/668bd1731d712a002335f7d7/images/1-1%20%281%29.jpg?v=2024-07-08T11:54:17.127Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/668bd1731d712a002335f7d7/images/1-1%20%281%29.jpg?v=2024-07-08T11:54:17.127Z"} />
		</Helmet>
		<Components.Header12>
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="link2" />
			<Override slot="link3" />
		</Components.Header12>
		<Section min-height="100vh" padding="0 0 0 0">
			<Override
				slot="SectionContent"
				flex-direction="row"
				flex-wrap="wrap"
				max-width="none"
				width="100%"
				sm-padding="72px 0px 0px 0px"
			/>
			<Box
				display="flex"
				width="40%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				sm-margin="0px 0px 50px 0px"
				margin="40px 5% 40px 5%"
				lg-padding="24px 60px 24px 60px"
				sm-padding="0px 20px 0px 20px"
				padding="24px 0px 24px 0px"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--dark"
					font="--headline1"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Menümüzü Deneyin
				</Text>
				<Text margin="0px 0px 40px 0px" color="--darkL2" font="--lead" lg-text-align="center">
					Coffee Haven'da çeşitli ve nefis içecek ve atıştırmalık seçenekleri sunmaktan gurur duyuyoruz. Menümüz, klasik kahve tutkunlarından benzersiz bir lezzet deneyimi arayanlara kadar herkese uygun bir şeyler sunmak için özenle seçilmiştir. Sizi rahat ve davetkar atmosferimizi tamamlayan bir mutfak yolculuğuna çıkarırken bize katılın.
				</Text>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="center"
				overflow-y="hidden"
				overflow-x="hidden"
				lg-width="100%"
				background="#FFFFFF url(https://uploads.quarkly.io/668bd1731d712a002335f7d7/images/1-4.jpg?v=2024-07-08T11:54:17.127Z) bottom/cover no-repeat scroll padding-box"
				lg-min-height="400px"
				lg-background="#FFFFFF url(https://uploads.quarkly.io/612695d67f2b1f001fa06c1f/images/bg-green.jpg?v=2021-08-26T00:39:08.141Z) 100% 75%/cover no-repeat scroll padding-box"
				md-background="#FFFFFF url(https://uploads.quarkly.io/612695d67f2b1f001fa06c1f/images/bg-green.jpg?v=2021-08-26T00:39:08.141Z) 100% 100%/cover no-repeat scroll padding-box"
				sm-background="#FFFFFF url(https://uploads.quarkly.io/612695d67f2b1f001fa06c1f/images/bg-green.jpg?v=2021-08-26T00:39:08.141Z) 50% 100%/cover no-repeat scroll padding-box"
			/>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Specials-Menu">
			<Override slot="SectionContent" flex-direction="column" />
			<Box
				width="100%"
				margin="0px 0px 40px 0px"
				padding="24px 24px 24px 24px"
				sm-padding="0px 0px 0px 0px"
				display="flex"
				flex-direction="column"
				align-items="center"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--dark"
					font="--headline2"
					text-align="center"
					lg-text-align="center"
					sm-font="normal 700 36px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					md-font="normal 700 36px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Bir Lezzetler Senfonisi
				</Text>
			</Box>
			<Box
				width="100%"
				display="grid"
				flex-direction="column"
				grid-template-columns="repeat(2, 1fr)"
				grid-template-rows="auto"
				grid-gap="40px"
				md-grid-template-columns="1fr"
				md-grid-gap="30px"
				sm-grid-template-columns="1fr"
			>
				<Box
					display="flex"
					flex-direction="column"
					align-items="flex-start"
					padding="24px"
					background="--color-light"
					border-radius="8px"
					box-shadow="0 4px 8px rgba(0, 0, 0, 0.1)"
				>
					<Text margin="0px 0px 16px 0px" font="--headline3" color="--dark">
						Menümüzü Deneyin
					</Text>
					<Text margin="0px 0px 24px 0px" color="--darkL2" font="--lead">
						Coffee Haven'da çeşitli ve nefis içecek ve atıştırmalık seçenekleri sunmaktan gurur duyuyoruz. Menümüz, klasik kahve tutkunlarından benzersiz bir lezzet deneyimi arayanlara kadar herkese uygun bir şeyler sunmak için özenle seçilmiştir. Sizi rahat ve davetkar atmosferimizi tamamlayan bir mutfak yolculuğuna çıkarırken bize katılın.
					</Text>
				</Box>
				<Box
					display="flex"
					flex-direction="column"
					align-items="flex-start"
					padding="24px"
					background="--color-light"
					border-radius="8px"
					box-shadow="0 4px 8px rgba(0, 0, 0, 0.1)"
				>
					<Text margin="0px 0px 16px 0px" font="--headline3" color="--dark">
						Tutkuyla Üretildi
					</Text>
					<Text margin="0px 0px 24px 0px" color="--darkL2" font="--lead">
						Menümüzdeki her ürün tutku ve hassasiyetle hazırlanmıştır. Her yudumun ve lokmanın benzersiz bir tatmin sunmasını sağlamak için yalnızca en iyi malzemeleri tedarik ediyoruz. Yetenekli baristalarımız ve mutfak ekibimiz, farklı zevklere ve tercihlere hitap eden bir dizi seçeneği size sunmak için yorulmadan çalışıyor.
					</Text>
				</Box>
				<Box
					display="flex"
					flex-direction="column"
					align-items="flex-start"
					padding="24px"
					background="--color-light"
					border-radius="8px"
					box-shadow="0 4px 8px rgba(0, 0, 0, 0.1)"
				>
					<Text margin="0px 0px 16px 0px" font="--headline3" color="--dark">
						İmzalı Kahve Kreasyonları
					</Text>
					<Text margin="0px 0px 16px 0px" color="--darkL2" font="--lead">
						Espresso: Espressomuz, taze çekilmiş çekirdeklerden yapılan, güçlü ve aromatik bir lezzettir.
						<br />
						Tek Atış: Hızlı güçlendirme için mükemmeldir.
						<br />
						Double Shot: Ekstra vuruşa ihtiyaç duyanlar için.
						<br />
						Cappuccino: Espresso, buharlanmış süt ve köpüğün uyumlu karışımı.
						<br />
						Klasik: Geleneksel favori.
						<br />
						Vanilya: Vanilya şurubuyla tatlı bir dokunuş.
						<br />
						Latte: Espresso ve buharlanmış sütün mükemmel dengesiyle pürüzsüz ve kremsi.
						<br />
						Klasik Latte: Basit ve tatmin edici.
						<br />
						Mocha Latte: Zengin çikolata aromasına sahiptir.
						<br />
						Soğuk Demleme: Pürüzsüz, canlandırıcı bir kahve deneyimi için yavaş demlenir.
						<br />
						Orijinal: Saf ve katkısız.
						<br />
						Vanilyalı Tatlı Krem: Kremsi, tatlı bir çeşittir.
					</Text>
				</Box>
				<Box
					display="flex"
					flex-direction="column"
					align-items="flex-start"
					padding="24px"
					background="--color-light"
					border-radius="8px"
					box-shadow="0 4px 8px rgba(0, 0, 0, 0.1)"
				>
					<Text margin="0px 0px 16px 0px" font="--headline3" color="--dark">
						Çay ve Alternatifler
					</Text>
					<Text margin="0px 0px 16px 0px" color="--darkL2" font="--lead">
						Chai Latte: Buharla pişirilmiş sütle baharatlı çay karışımı.
						<br />
						Klasik Chai: İçinizi ısıtan bir deneyim için geleneksel baharatlar.
						<br />
						Vanilla Chai: Daha tatlı, vanilyalı bir versiyon.
						<br />
						Bitki Çayları: Sakinleştirici ve canlandırıcı bitki karışımlarından oluşan bir seçki.
						<br />
						Papatya: Rahatlamak için mükemmeldir.
						<br />
						Nane: Canlandırıcı ve rahatlatıcı.
						<br />
						Smoothies: Doğal malzemelerle taze harmanlanmıştır.
						<br />
						Berry Blast: Çilek ve yoğurt karışımı.
						<br />
						Yeşil Tanrıça: Sağlıklı bir destek için ıspanak, muz ve elma.
					</Text>
				</Box>
				<Box
					display="flex"
					flex-direction="column"
					align-items="flex-start"
					padding="24px"
					background="--color-light"
					border-radius="8px"
					box-shadow="0 4px 8px rgba(0, 0, 0, 0.1)"
				>
					<Text margin="0px 0px 16px 0px" font="--headline3" color="--dark">
						Lezzetli Hamur İşleri ve Atıştırmalıklar
					</Text>
					<Text margin="0px 0px 16px 0px" color="--darkL2" font="--lead">
						Muffinler: Her gün taze olarak çeşitli tatlarla pişirilir.
						<br />
						Yabanmersinli Muffin: Klasik ve lezzetli.
						<br />
						Çikolatalı Muffin: Tatlı bir ikram için.
						<br />
						Kruvasanlar: Pul pul ve tereyağlıdır, her türlü içeceğin yanında mükemmeldir.
						<br />
						Sade Kruvasan: Klasik tereyağlı lezzet.
						<br />
						Bademli Kruvasan: Ekstra lezzet için badem ezmesiyle doldurulmuştur.
						<br />
						Kurabiyeler: Yumuş

ak ve çiğnenebilir, günün her saati için idealdir.
						<br />
						Çikolata Parçalı Kurabiye: Zamanın ötesinde bir favori.
						<br />
						Yulaf ezmeli kuru üzüm kurabiyesi: Doyurucu, sağlıklı bir seçenek.
						<br />
						Sandviçler: Taze malzemelerle sipariş üzerine hazırlanır.
						<br />
						Hindi ve Peynir: Lezzetli bir seçim.
						<br />
						Veggie Delight: Taze sebzelerle paketlenmiştir.
					</Text>
				</Box>
				<Box
					display="flex"
					flex-direction="column"
					align-items="flex-start"
					padding="24px"
					background="--color-light"
					border-radius="8px"
					box-shadow="0 4px 8px rgba(0, 0, 0, 0.1)"
				>
					<Text margin="0px 0px 16px 0px" font="--headline3" color="--dark">
						Özel Ürünler ve Etkinlikler
					</Text>
					<Text margin="0px 0px 16px 0px" color="--darkL2" font="--lead">
						Sezona Özel Fırsatlar: Mevsimlere göre değişen eşsiz lezzetlerin tadını çıkarın.
						<br />
						Pumpkin Spice Latte: Sonbaharın favorisi.
						<br />
						Nane Mocha: Kış için mükemmeldir.
						<br />
						Kahve Tadım Etkinlikleri: Dünyanın dört bir yanından farklı kahve karışımlarını deneyebileceğiniz ve öğrenebileceğiniz özel etkinlikler için bize katılın.
						<br />
						Özel Rezervasyonlar: Özel toplantılarınız veya iş toplantılarınız için yerimizi ayırın. Menümüze özel erişimin ve özel baristanın keyfini çıkarın.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Explore-More">
			<Override slot="SectionContent" flex-direction="column" />
			<Box
				width="100%"
				margin="0px 0px 40px 0px"
				padding="24px 24px 24px 24px"
				sm-padding="0px 0px 0px 0px"
				display="flex"
				flex-direction="column"
				align-items="center"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--dark"
					font="--headline2"
					text-align="center"
					lg-text-align="center"
					sm-font="normal 700 36px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					md-font="normal 700 36px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Daha fazlasını keşfet
				</Text>
			</Box>
			<Box
				width="100%"
				display="grid"
				flex-direction="column"
				grid-template-columns="1fr"
				grid-template-rows="auto"
				grid-gap="40px"
				md-grid-gap="30px"
			>
				<Box
					display="flex"
					flex-direction="column"
					align-items="flex-start"
					padding="24px"
					background="--color-light"
					border-radius="8px"
					box-shadow="0 4px 8px rgba(0, 0, 0, 0.1)"
				>
					<Text margin="0px 0px 24px 0px" color="--darkL2" font="--lead">
						Bu, Coffee Haven'da sunduklarımıza sadece bir bakış. Sizi tüm menümüzü keşfetmeye ve mevcut tüm lezzetli seçenekleri keşfetmeye davet ediyoruz. Diyetle ilgili endişeleriniz veya özel istekleriniz varsa lütfen bizimle iletişime geçmekten çekinmeyin. Ekibimiz her zaman size yardımcı olmaya ve deneyiminizin keyifli olmasını sağlamaya hazırdır.
					</Text>
				</Box>
				<Box
					display="flex"
					flex-direction="column"
					align-items="flex-start"
					padding="24px"
					background="--color-light"
					border-radius="8px"
					box-shadow="0 4px 8px rgba(0, 0, 0, 0.1)"
				>
					<Text margin="0px 0px 24px 0px" color="--darkL2" font="--lead">
						Tekliflerimiz hakkında daha fazla bilgi edinmek veya sipariş vermek için lütfen bizi ziyaret edin veya bizimle iletişime geçin. Sizi Coffee Haven'da ağırlamayı ve olağanüstü menümüzle gününüzü biraz daha parlak hale getirmeyi sabırsızlıkla bekliyoruz.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Contact-Info">
			<Override slot="SectionContent" flex-direction="column" />
			<Box
				width="100%"
				margin="0px 0px 40px 0px"
				padding="24px 24px 24px 24px"
				sm-padding="0px 0px 0px 0px"
				display="flex"
				flex-direction="column"
				align-items="center"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--dark"
					font="--headline2"
					text-align="center"
					lg-text-align="center"
					sm-font="normal 700 36px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					md-font="normal 700 36px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Bize Ulaşın
				</Text>
			</Box>
			<Box
				width="100%"
				display="grid"
				flex-direction="column"
				grid-template-columns="1fr"
				grid-template-rows="auto"
				grid-gap="40px"
				md-grid-gap="30px"
			>
				<Box
					display="flex"
					flex-direction="column"
					align-items="flex-start"
					padding="24px"
					background="--color-light"
					border-radius="8px"
					box-shadow="0 4px 8px rgba(0, 0, 0, 0.1)"
				>
					<Text margin="0px 0px 24px 0px" color="--darkL2" font="--lead">
						Tekliflerimiz hakkında daha fazla bilgi edinmek veya sipariş vermek için lütfen bizi ziyaret edin veya bizimle iletişime geçin. Sizi Coffee Haven'da ağırlamayı ve olağanüstü menümüzle gününüzü biraz daha parlak hale getirmeyi sabırsızlıkla bekliyoruz.
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Footer12 />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"658eb1148e2e8e00217540ff"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});